html {
    font-size: 62.5%;
    transition: font-size 0.5s ease;
    @media screen and (max-width: $small-screen) {
        font-size: 52.5%;
    }
}
body {
    font-size: $small-font-size;
    text-align: center;
    font-family: $body-font-family;
    background-color: $background-color;
}
header {
    width: 100%;
    margin: 0 auto;
    div:nth-child(1) {
        float: left;
    }
    div:nth-child(2) {
        float: right;
    }
}
h1 {
    font-family: $heading-font-family;
    font-size: $xlarge-font-size;
    font-weight: bold;
    font-style: normal;
    color: $primary-color;
    margin: 3rem 0 0 0;
    span {
        font-size: $large-font-size;
        display: block;
        line-height: 0.5;
        font-style: italic;
        margin: 0.5rem 0 0rem 0;
    }
}
h2 {
    margin: 0;
    font-size: $large-font-size;
    color: darken($secondary-color, 15);
    margin-bottom: 0.5rem;
    font-weight: normal;
}
p {
    width: 100%;
    margin: 1rem auto;
    color: lighten($primary-color, 10);
    font-size: $medium-font-size;
}
a {
    text-decoration: none;
}
footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: $quaterary-color;
    border-top: 2px $secondary-color dotted;
    p {
        margin: 0.3rem 0;
        padding: 0;
        font-size: $small-font-size;
    }
}
.entry {
    font-size: $medium-font-size;
    color: lighten($primary-color, 15);
    text-align: left;
}
.wrapper {
    max-width: 1600px;
    width: 80%;
    margin: 0 auto;
    color: $secondary-color;
    @media screen and (max-width: $small-screen) {
        width: 90%;
    }
}
// horizontal alignment for questions and final form
// .input-wrapper
.question {
    max-width: 650px;
    margin: 10rem auto;
}
.question p {
    color: lighten($primary-color, 20);
}
.app-header {
    margin: 2rem 0 4rem 0;
    color: darken($secondary-color, 30);
    font-size: $large-font-size;
}
.modal-container,
.modal {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;
    h2 {
        text-decoration: underline;
        margin: 2rem 0 1rem 0;
        color: $primary-color;
    }
}
.modal-container {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 2%;
    bottom: 2%;
    background: whitesmoke;
    border: 6px double $primary-color;
    transition: left 0.5s ease, right 0.5s ease;
    @media screen and (max-width: $small-screen) {
        left: 5%;
        right: 5%;
    }
}
.input-field {
    border: 5px double $primary-color;
    padding: 1rem;
    font-size: $medium-font-size;
    display: block;
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 1rem;
    transition: width 0.5s ease;
    @media screen and (max-width: $small-screen) {
        width: 100%;
    }
}
.instruction {
    font-weight: bold;
    color: $primary-color;
    p {
        margin: 0;
    }
}
.definition {
    font-size: $large-font-size;
    color: $primary-color;
}
.entries {
    display: inline-block;
    margin-top: 1.5rem;
}
.dev-name {
    margin-right: 1rem;
    font-family: $heading-font-family;
    font-size: $small-font-size;
    color: $primary-color;
}
svg {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    path {
        fill-opacity: 0;
        stroke: $primary-color;
        stroke-width: 1;
        stroke-dasharray: 870;
        stroke-dashoffset: 870;
        animation: draw 15s ease;
        animation-fill-mode: forwards;
    }
}
.timerSVG {
    animation: draw 15s ease, shake 3s ease;
    path {
        stroke-width: 1.5;
    }
}
@keyframes draw {
    from {
        stroke-dashoffset: 870;
    }
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes shake {
    10%,
    90% {
        transform: translate3d(-2px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(4px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-8px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(8px, 0, 0);
    }
}
