article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:focus {
  outline: thin dotted; }

a:active, a:hover {
  outline: 0; }

h1 {
  font-size: 2em; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: \201C \201D \2018 \2019; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em; }

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button, input {
  line-height: normal; }

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], input[disabled] {
  cursor: default; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body, figure {
  margin: 0; }

legend, button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: '';
  clear: both;
  height: 0; }

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0);
  overflow: hidden; }

html {
  font-size: 62.5%;
  transition: font-size 0.5s ease; }
  @media screen and (max-width: 600px) {
    html {
      font-size: 52.5%; } }

body {
  font-size: 1.8rem;
  text-align: center;
  font-family: "Crimson Text", serif;
  background-color: #f5f5f5; }

header {
  width: 100%;
  margin: 0 auto; }
  header div:nth-child(1) {
    float: left; }
  header div:nth-child(2) {
    float: right; }

h1 {
  font-family: "Lora", serif;
  font-size: 7rem;
  font-weight: bold;
  font-style: normal;
  color: #454747;
  margin: 3rem 0 0 0; }
  h1 span {
    font-size: 3.5rem;
    display: block;
    line-height: 0.5;
    font-style: italic;
    margin: 0.5rem 0 0rem 0; }

h2 {
  margin: 0;
  font-size: 3.5rem;
  color: #989a9b;
  margin-bottom: 0.5rem;
  font-weight: normal; }

p {
  width: 100%;
  margin: 1rem auto;
  color: #5e6161;
  font-size: 2.2rem; }

a {
  text-decoration: none; }

footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  border-top: 2px #bfc0c1 dotted; }
  footer p {
    margin: 0.3rem 0;
    padding: 0;
    font-size: 1.8rem; }

.entry {
  font-size: 2.2rem;
  color: #6b6e6e;
  text-align: left; }

.wrapper {
  max-width: 1600px;
  width: 80%;
  margin: 0 auto;
  color: #bfc0c1; }
  @media screen and (max-width: 600px) {
    .wrapper {
      width: 90%; } }

.question {
  max-width: 650px;
  margin: 10rem auto; }

.question p {
  color: #777b7b; }

.app-header {
  margin: 2rem 0 4rem 0;
  color: #727475;
  font-size: 3.5rem; }

.modal-container,
.modal {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9; }
  .modal-container h2,
  .modal h2 {
    text-decoration: underline;
    margin: 2rem 0 1rem 0;
    color: #454747; }

.modal-container {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 2%;
  bottom: 2%;
  background: whitesmoke;
  border: 6px double #454747;
  transition: left 0.5s ease, right 0.5s ease; }
  @media screen and (max-width: 600px) {
    .modal-container {
      left: 5%;
      right: 5%; } }

.input-field {
  border: 5px double #454747;
  padding: 1rem;
  font-size: 2.2rem;
  display: block;
  width: 80%;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 1rem;
  transition: width 0.5s ease; }
  @media screen and (max-width: 600px) {
    .input-field {
      width: 100%; } }

.instruction {
  font-weight: bold;
  color: #454747; }
  .instruction p {
    margin: 0; }

.definition {
  font-size: 3.5rem;
  color: #454747; }

.entries {
  display: inline-block;
  margin-top: 1.5rem; }

.dev-name {
  margin-right: 1rem;
  font-family: "Lora", serif;
  font-size: 1.8rem;
  color: #454747; }

svg {
  width: 200px;
  height: 200px;
  margin: 0 auto; }
  svg path {
    fill-opacity: 0;
    stroke: #454747;
    stroke-width: 1;
    stroke-dasharray: 870;
    stroke-dashoffset: 870;
    animation: draw 15s ease;
    animation-fill-mode: forwards; }

.timerSVG {
  animation: draw 15s ease, shake 3s ease; }
  .timerSVG path {
    stroke-width: 1.5; }

@keyframes draw {
  from {
    stroke-dashoffset: 870; }
  to {
    stroke-dashoffset: 0; } }

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-2px, 0, 0); }
  20%,
  80% {
    transform: translate3d(4px, 0, 0); }
  30%,
  50%,
  70% {
    transform: translate3d(-8px, 0, 0); }
  40%,
  60% {
    transform: translate3d(8px, 0, 0); } }

button {
  overflow: visible;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  font: inherit;
  line-height: normal;
  display: inline-block;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 2.2rem;
  padding: 8px 16px 16px 16px;
  margin: 10px 10px;
  background-color: #ffffff;
  color: #454747;
  border: 2px solid #454747;
  transition: color .3s ease, transform .3s ease;
  overflow: hidden;
  position: relative;
  z-index: 3; }

@keyframes stripe-slide {
  0% {
    background-position: 0% 0; }
  100% {
    background-position: 100% 0; } }
  button:after {
    content: '';
    height: 7px;
    width: 100%;
    background-image: repeating-linear-gradient(45deg, #454747, #454747 1px, transparent 2px, transparent 5px);
    border-top: 1px solid #454747;
    position: absolute;
    left: 0;
    bottom: 0;
    background-size: 7px 7px; }
  button:hover {
    background-color: #3ea2a6;
    color: #ffffff;
    border-color: #000000; }
    button:hover:after {
      background-image: repeating-linear-gradient(45deg, #ffffff, #ffffff 1px, transparent 2px, transparent 5px);
      border-top: 1px solid #000000;
      animation: stripe-slide 5s infinite linear forwards; }
  button:focus {
    outline: none; }
  button:active {
    transform: translateY(9px);
    transition: all 0.01s;
    overflow: visible;
    border-bottom: none; }
    button:active:after {
      position: absolute;
      background-color: #f5f5f5;
      left: -3%;
      width: 106%;
      background-image: none;
      border-top: 4px solid #000000;
      z-index: 1; }
      @media screen and (max-width: 600px) {
        button:active:after {
          border-top: 3px solid #000000; } }
    button:active:before {
      content: '';
      width: 100%;
      height: 80%;
      position: absolute;
      bottom: 20%;
      left: 0;
      outline: 8px solid #f5f5f5;
      outline-offset: 2px;
      z-index: 2; }

.btn-leaderboard {
  z-index: 10;
  margin-bottom: 5rem; }
