button {
  @include reset;
  display: inline-block;
  text-decoration: none;
  text-transform: capitalize;
  font-size: $medium-font-size;
  padding: 8px 16px 16px 16px;
  margin: 10px 10px;
  background-color: $quaterary-color;
  color: $primary-color;
  border: 2px solid $primary-color;
  transition: color .3s ease, transform .3s ease;
  overflow: hidden;
  position: relative;
  z-index: 3;
  @keyframes stripe-slide {
    0% { background-position: 0% 0; }
    100% { background-position: 100% 0; }
  }
      
  &:after {
    content: '';
    height: $stripe-height;
    width: 100%;
    background-image: repeating-linear-gradient(
        45deg,
        $primary-color,
        $primary-color 1px,
        transparent 2px,
        transparent 5px
    );
    border-top: 1px solid $primary-color;
    position: absolute;
    left: 0;
    bottom: 0;
    background-size: $stripe-height $stripe-height;
  }
  &:hover{
    background-color: $accent-color;
    color: $quaterary-color;
    border-color: $tertiary-color;
    &:after {
      background-image: repeating-linear-gradient(
        45deg,
        $quaterary-color,
        $quaterary-color 1px,
        transparent 2px,
        transparent 5px
      );
      border-top: 1px solid $tertiary-color;
      animation: stripe-slide 5s infinite linear forwards;
    }
  }
  &:focus {
    outline: none;
  }
  &:active {
    &:after{
      position: absolute;
      background-color: $background-color;
      left: -3%;
      width: 106%;
      background-image: none;
      border-top: 4px solid $tertiary-color;
      z-index: 1;
      @media screen and (max-width: $small-screen){
        border-top:3px solid $tertiary-color;
      }
    }
    &:before{
      content: '';
      width: 100%;
      height: 80%;
      position: absolute;
      bottom: 20%;
      left: 0;
      outline: 8px solid $background-color;
      outline-offset: 2px;
      z-index: 2;
    }
    transform: translateY(9px);
    transition: all 0.01s;
    overflow: visible;
    border-bottom: none;
  }
}

.btn-leaderboard{
  z-index: 10;
  margin-bottom: 5rem;
}