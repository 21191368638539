$primary-color: #454747;
$secondary-color: #bfc0c1; // not using right now
$tertiary-color: #000000;
$quaterary-color: #ffffff;
$accent-color: #3ea2a6;
// $accent-color: grey;
$background-color: #f5f5f5;
$monogram-color: #606060;

$heading-font-family: 'Lora', serif;
$body-font-family: 'Crimson Text', serif;

$small-font-size: 1.8rem;
$medium-font-size: 2.2rem;
$large-font-size: 3.5rem;
$xlarge-font-size: 7rem;

$stripe-height: 7px;

$small-screen: 600px;